import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby-plugin-react-intl"
import { GatsbyImage } from "gatsby-plugin-image"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

//Components
import SEO from "../components/seo"
import Layout from "../components/layout"
import Title from "../components/title"
import Vaccine from "../components/vaccine-banner"

// Styles
import { Container, Row, Col, Button } from "react-bootstrap"
import * as styles from "../styles/templates/service.module.scss"

export const query = graphql`
  query ($slug: String!) {
    contentfulServices(slug: { eq: $slug }) {
      slug
      title
      content {
        raw
      }
      image {
        gatsbyImageData(
          quality: 100
          width: 400
          height: 400
          layout: FULL_WIDTH
        )
      }
    }
  }
`

export default function Doctor({ data }, props) {
  const Bold = ({ children }) => (
    <span>
      <strong>{children}</strong>
    </span>
  )
  const Text = ({ children }) => <p className={styles.text}>{children}</p>

  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
      [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
      [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
      [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
      // This is not tested
      [BLOCKS.EMBEDDED_ASSET]: node => {
        return (
          <>
            <h2>Embedded Asset</h2>
            <pre>
              <code>{JSON.stringify(node, null, 2)}</code>
            </pre>
          </>
        )
      },
    },
  }

  const service = data.contentfulServices

  return (
    <Layout>
      <SEO title={service.title} />
      <Title title={service.title} />
      <Container>
        <Row className="my-5 ">
          <Col xs={12} xl={10} className="my-5 mx-auto">
            <Row className="my-5 align-items-center g-5">
              <Col md={6}>
                <div className="p-5">
                  <GatsbyImage
                    image={service.image.gatsbyImageData}
                    alt={service.title}
                  />
                </div>
              </Col>
              <Col md={6}>
                <h2 className={`${styles.title} mb-4`}>{service.title}</h2>
                <h4 className={styles.sub}>
                  Kids &amp; Teens Medical Group <br />
                  {service.title} in Los Angeles
                </h4>
                <hr className="my-4" />

                {renderRichText(service.content, options)}

                {service.slug === "circumcision" && (
                  <a href="/resources/circumcision.pdf" target="_blank">
                    <Button className="mt-2 me-2 py-3 btn-success">
                      View Brochure
                    </Button>
                  </a>
                )}
                <Link to="/locations">
                  <Button className="mt-2">Book an Appointment</Button>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Container fluid className="p-0">
        <Vaccine />
      </Container>
    </Layout>
  )
}
