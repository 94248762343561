import React from "react"
import { Row, Col, Button } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby-plugin-react-intl"
import * as styles from "../styles/components/vaccine.module.scss"
import { useIntl } from "gatsby-plugin-react-intl"

export default function Vaccine() {
  const intl = useIntl()

  return (
    <Row className={`${styles.wrapper} d-flex align-items-center`} id="vaccine">
      <Col md={4} className={`${styles.content} p-5`}>
        <div className="p-xl-5">
          {/* Title */}
          <h1 className={`${styles.title} my-4`}>
            {intl.formatMessage({ id: "covidVaccineLong" })}
          </h1>
          <Link to="/signature-services/free-vaccines">
            <Button> {intl.formatMessage({ id: "learnMore" })}</Button>
          </Link>
        </div>
      </Col>

      <Col md={8} className="p-0 align-self-stretch">
        {/* Image */}
        <StaticImage
          src="../images/vaccinations.jpg"
          layout="fullWidth"
          objectFit="cover"
          className={styles.img}
          alt=""
        />
      </Col>
    </Row>
  )
}
